import React from "react";
import cbLogo from "../images/logo/curiousbits_logo_large.svg";

const Header = () => (
  <header>
    <nav className="navbar navbar-light border-bottom" style={{ marginBottom: "1.25rem"}}>
      <a className="navbar-brand" href="/">
        <img src={cbLogo} height="52" alt="CuriousBits" />
      </a>
    </nav>
  </header>
);

export default Header;
