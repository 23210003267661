import React from "react";
import styled from "styled-components";

const FlipFlop = styled.footer`
    margin-top: 1.25rem;
`;

const FlipFlopText = styled.small`
    line-height: 2.2rem;
`;
const Footer = () => (
  <FlipFlop className="border-top container">
    <FlipFlopText className="float-right" style={{ lineHeight: "2.2rem" }}>
      CoC number: 73629316
      <b> | </b>
      <a href="/terms">
        Terms & Conditions
      </a>
    </FlipFlopText>
  </FlipFlop>
);

export default Footer;
