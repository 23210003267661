import React from "react";

import PropTypes from "prop-types";

class ContentBlock extends React.Component {
  render() {
    return (
      <div className="contentblock row">
        <div className="col-md-12">
          <div className="row justify-content-md-center">
            { this.props.title && (
              <div className="col-md-12">
                <h2>{this.props.title}</h2>
              </div>
            )}
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }
}

ContentBlock.defaultProps = {
  title: ""
};

ContentBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ContentBlock;
